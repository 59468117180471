.vehicleStatusContainer{
    color: #FFFFFF;
    font-family: var(--fmc-font--antenna);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    width: fit-content;
    padding: 5px;
}
.saveAndCancelBtnContainer{
    display: flex;
    justify-content: space-evenly;
}
.backGroundText{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size:96px;
    top:20%;
}

.vehicleContainer{
    background-color: #4d4d4d; /* #cdc6c636 */
    opacity:0.2;
}

.menuItemContainer ul{
    top: 10px !important;
    left: -80px !important;
}

.headerContainer{
    box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.1), 0 2rem 2rem 0 rgba(0, 0, 0, 0.1), 0 3rem 3rem 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
    background-Color: white;
}

.headerContainerProduct{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
}

.profileUser{
    margin-top: 15px;
    width: 50%;
    margin-bottom: 50px;
}
.editFormContainer{
    margin: 0  0 7rem 7rem;
}

.editViewFieldContainer{
    display: flex;
    justify-content: space-between;
}

.editViewTableContainer > table{
    width: 100% ;
}
.editViewTableContainer td {
    border-color: #f2f2f2 !important;
    text-align: left;

}
.editViewTableContainer th {
    background-color: #FFFFFF !important;
    text-align: left;
    color: #4D4D4D !important;
    border-color: #f2f2f2 !important;
    font-weight: normal !important ;
}

.accountBtnContainer{
    margin: auto !important;
}

.breadcrumbHover:active{
    text-decoration: underline;
}

.breadcrumbHover:hover{
    text-decoration: underline;
    cursor: pointer;

}



.menuContainer{
    padding: 1em 1em 0.5em 5em;
}

.vl {
    border-left: 1px solid #00000040;
    height: 86px;
    margin-left: 13px;
}

.imageContainer{
    height: 3em;
    padding-top: 1em;
}

.cardTitle{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 1em;
}

.cardDescription{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    padding-top: 1em;
}

.vehicleTopPadding{
    margin-top: 6em;
}

@media screen and (max-width: 630px) {
    .profileUser{
        margin-top: 15px;
        width: 100%;
    }
    .editFormContainer > div{
        margin-top: 2rem;
        margin-left: 1rem;
        padding: 0 !important;
    }

}

