.yourVehiclesTitle{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}

.vehiclesList > div{
    margin-left: 20px;
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.privacyContainer{
    margin-top: 22.5px;
}

.privacy > div{
    margin-left: 20px;
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.privacy span{
    color: #1700F4;
}

.contactDesc{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.eapResearchPanel{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #4D4D4D;
}

.textInputBody {
    color: #00095B;
    font-family: "Ford Antenna";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.optionsStyle {
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    display: block;
}

.modalCenter > div{
    right: auto !important;
}

.modalScroll > div{
    overflow-y: hidden !important;
    left: unset !important;
    right: unset !important;
}

.helpDrive{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
}

.vinNumber{
    display: block;
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0;
    margin: 0 !important;
}

.description{
    margin-top: 18px;
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 25px;
}

.separatorContainer{
    margin-top: 22.5px;
    padding-top: 22.5px;
    margin-bottom: 40px;
    border-top: 1px solid #4D4D4D;
}

.termsDesc{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}