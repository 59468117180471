.title{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
}

.noticeText {
    color: #4D4D4D;
    padding: 1em 0 1em 0;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
}