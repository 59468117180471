.paddingSides{
    padding: 10px 100px;
}

.ctaBtnContainer{
    display: flex;
    justify-content: space-evenly;
}

.eapTermsLink{
    text-decoration: underline #00095B;
}

.headerText{
    text-align: left;
    font-size: 18px;
    font-weight: 400;
}

@media only screen and (max-width: 630px) {
    .paddingSides{
        padding: 5px 20px;
    }
}
