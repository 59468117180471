body{
    font-family:var(--fmc-font--antenna) !important;
}
.headerFordLogo {
  padding: 14px 0;
    display: flex;
    justify-content: space-between;

}
.headerFordLogo img{
    margin-top: 5px;
    width: 70px;
    block-size: fit-content;
}


.headerFordLogo .logoText{
    padding: 5px 0 0 21px;
    color: #FFFFFF;
    font-family: var(--fmc-font--antenna) ;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;

}
.headerFordLogo .logoTextSeparator{
    padding: 9px 0 0 0;
    margin-left: 18px;
    margin-top: 5px;
    height: 21px;
    width: 1px;
    opacity: 0.4;
    transform: scaleX(-1);
    background-color: #FFFFFF;
    font-family: var(--fmc-font--antenna) ;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;

}


.navBarContainer {
    background-color: #00095b !important;
    z-index: 2001;
    padding: 7px 0 7px 25px;
}

.mainNavigationContainer{
    padding: 0 !important;
    margin-left: 75px !important;
}

.mainNavigationContainerOverlay{
    display: none;
}
.mainNavigationContainerOverlayOpen{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    width: 100%;
    height: 100%;
    opacity: .7;
    background: #000 none;
    display: block;
}


.header {
    background: #00095b;
    padding: 0 10px;
    height: 90px !important;
}
.NavContainer{
    display: flex;
    column-gap: 2.5em;
    background-color: #00095B;
    padding: 1em 6.875em 1em 6.875em ;
    align-items: center;
    font-family: var(--fmc-font--antenna);
    font-stretch: condensed;
    height: 80px;
}
.logoTextContainer{
    display: flex;
    column-gap: 15px;
    font-stretch: condensed;
}

.logoTextContainer >img {
    height: 25px;
}
.logoText{
    color: #FFFFFF !important;
    font-family: var(--fmc-font--antenna) ;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 200;

}

.logoTextSeparator{
    padding: 9px 0 0 0;
    margin-top: 5px;
    height: 20px;
    width: 1px;
    opacity: 0.4;
    transform: scaleX(-1);
    background-color: #FFFFFF !important;
    font-family: var(--fmc-font--antenna) ;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;

}
.mainContainer{
    background-color: #00095b;
    display: flex;
    justify-content: space-between;
    padding: 20px 50px 5px 100px;
}

.headerRight {
    float: right;
    margin-top: 18px;
    padding-right: 78px;
}

.myAccountContainer {
    font-size: 16px;
    font-family: var(--fmc-font--antenna);
    color: #FFF;
    padding-left: 20px;
    padding-top: 5px;
    float: left;
    font-stretch: condensed;
    font-weight: lighter;
}

.myAccountContainer>a {
    color: #FFF !important;
}

.myAccountContainer >div{
    top:58px !important;

}
.myAccountChevron{
    color: #FFF !important;
}

.innerDropDown {
    min-width:344px;
    padding: 5px;
}

.innerDropDown a:hover{
    background-color: transparent;
}


.innerDropDown  p:hover{
    color: #1700F4;
    font-weight: 500;
}

.accountInner {
    font-size: 16px;
    font-family: var(--fmc-font--antenna);
    padding: 5px;
    margin-bottom: 0 !important;

}

.accountInner > span {
    float: right;
    padding: 0;
    margin: 0 !important;
}

.accountInner >  span ::before{
    color: #00095B !important;
    font-weight: 600;
}

.myAccountContainer > a::after{
    content: none !important;
}

.profileIcon > img {
    min-width: 22px;
    min-height: 15px;
    margin-top: 10px;
    margin-right: -15px;
    float: left;
}

.topHeaderDividerContainer{
    width: 350px;
}

.contactUs {
    font-size: 16px;
    font-family: var(--fmc-font--antenna);
    color: #FFF;
    font-stretch: condensed;
    padding-left: 20px;
    padding-top: 10px;
    align-items: center;
}

.contactUs > img {

    margin-right: 15px;
    margin-top: -5px;
}
.mobileMenuText{
    display: none;

}
.modalContainer{
    z-index: 2002 !important;
}


.joinSignIn {
    font-size: 15px;
    font-family: var(--fmc-font--antenna);
}

.headerSignIn{
    display: flex;
    align-items: center;
    bottom: 7px;
    position: relative;
}

.headerSignIn > button{
    white-space: nowrap;
    margin-left: 5px;
}

.headerSignIn > span{

}

@media screen and (max-width: 991px) {

    .mobileMenuText{
        display:block;
        color:#FFF;
        margin-right: 15px;
        font-size: 10px;
        margin-left: 4px;

    }

    .mainNavigationContainer button{
        border: none !important;
        border-radius: 0 !important;
        font-size: 2.55rem;
        padding: 0 !important;


    }
    .toggleMenu span{
        background-image: url("../../../assets/Menu.svg") !important;
        max-width: 24px;
        margin-left: 8px;
        padding-top: 1px;
        line-height: 0 !important;
    }
    .toggleCloseMenu span{
        background-image: url("../../../assets/Close.svg") !important;
        max-width: 24px;
        margin-right: 5px;

    }

    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
        margin-right: 36px;
    }

    .navBarContainer{
        padding-left: 0 !important;
    }

    .contactUs{
        padding: 10px 0 10px 2px;
    }

    .innerDropDown {
        background-color: #FFF;
        padding: 8px;
    }
    .innerDropDown{
        width: 70vw;
        margin-left: calc(-28vw + 60%);
        padding: 9px;
    }

    .innerDropDown  .dropdownItemDivider{
        border-top: 1px solid rgba(0, 0, 0, 0.42);
        width: 70.5vw;
        margin-left: calc(-42vw + 60%);


    }
    .myAccountContainer>a {
        color: #FFF !important;

    }
    .topHeaderDividerContainer{
        width: 990px;
    }


    .myAccountContainer > div{
        background-color: transparent;
    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: #FFFFFF;
    }
    .headerFordLogo .logoTextSeparator{
        padding: 9px 0 0 0;
        margin-left: 12px;
        margin-top: 5px;
        height: 21px;
        width: 1px;
        opacity: 0.4;
        transform: scaleX(-1);
        background-color: #FFFFFF;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;

    }

    .mainNavigationContainer{
        margin-left: 70px !important;
    }

}

@media screen and (max-width: 768px) {


    .mobileMenuText{
        display:block;
        color:#FFF;
        margin-right: 15px;
        font-size: 10px;
        margin-left: 4px;

    }

    .mainNavigationContainer button{
        border: none !important;
        border-radius: 0 !important;
        font-size: 2.55rem;
        padding: 0;

    }

    .mainNavigationContainer{
        padding: 0 !important;
        margin-left: 40px !important;
    }
    .toggleMenu span{
        background-image: url("../../../assets/Menu.svg") !important;
        max-width: 24px;
        margin-left: 8px;
        padding-top: 1px;
        line-height: 0 !important;
    }
    .toggleCloseMenu span{
        background-image: url("../../../assets/Close.svg") !important;
        max-width: 24px;
        margin-right: 5px;

    }

    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
        margin-right: 30px;

    }

    .contactUs{
        padding: 10px 0 10px 2px;
    }

    .innerDropDown {
        background-color: #FFF;
        padding: 8px;
    }
    .innerDropDown{
        width: 102vw;
        margin-left: calc(-39vw + 46%);
        padding:9px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 110vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }
    .myAccountContainer>a {
        color: #FFF !important;
    }

    .mainContainer{
        background-color: #00095b;
        display: flex;
        justify-content: space-between;
        padding: 20px 20px 5px 20px;
    }


    .myAccountContainer > div{
        background-color: transparent;
    }
}

@media screen and (max-width: 630px) {
    .mobileMenuText{
        display:block;
        color:#FFF;
        margin-right: 15px;
        font-size: 10px;
        margin-left: 4px;

    }

    .mainNavigationContainer button{
        border: none !important;
        border-radius: 0 !important;
        font-size: 2.55rem;
        padding: 0;
    }
    .contactUs {
        font-size: 13px;
        font-family: var(--fmc-font--antenna);
    }

    .joinSignIn {
        font-size: 13px;
        font-family: var(--fmc-font--antenna);
    }

    .header {
        height: 58px;
    }

    .headerRight {
        margin-top: 0 !important;
        padding-right: 20px !important;
    }


    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
        margin-right: 40px;
    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: #FFFFFF;
    }

    .innerDropDown{
        width: 102vw;
        margin-left: calc(-40vw + 46%);
        padding:9px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 110vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }
}

@media screen and (max-width: 540px) {
    .contactUs{
        padding-left: 2px;
    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: #FFFFFF;
    }

    .innerDropDown{
        width: 102vw;
        margin-left: calc(-41.5vw + 46%);
        padding:9px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 110vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }

    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
        margin-right: 75px;
    }

    .toggleCloseMenu span{
        background-image: url("../../../assets/Close.svg") !important;
        max-width: 24px;
        margin-right: 35px;

    }

}

@media screen and (max-width: 414px){

    .mainNavigationContainer{
        padding: 0 !important;
        margin-left: 25px !important;
    }

    .contactUs {
        font-size: 16px;
        font-family: var(--fmc-font--antenna);
        font-stretch: condensed;
        padding: 15px 10px 15px 10px;
        margin-left: 10px;
    }

    .headerFordLogo {
        padding: 5px 0;
        zoom: 80%;

    }


    .joinSignIn {
        font-size: 13px;
        font-family: var(--fmc-font--antenna);
        font-stretch: condensed;
    }

    .myAccountContainer {
        font-size: 16px;
        font-family: var(--fmc-font--antenna);
        font-stretch: condensed;
        color: #000066;
        padding-left: 0;
        padding-top: 6px;
        float: left;

    }

    .profileIcon{
        max-height: 20px;
    }
    .profileIcon > img {
        min-width: 25px;
        min-height: 20px;
        margin-top: 10px;
        margin-right: 15px;
        float: left;

    }

    .contactUs > img {
        margin-left: -18px;
        margin-right: 15px;
        margin-top: -5px;
    }



    .innerDropDown {
        background-color: #FFF;
        padding: 8px;
    }

    .accountInner {
        font-size: 16px;
        font-family: var(--fmc-font--antenna);

    }

    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
        margin-right: 45px;


    }

    .innerDropDown{
        width: 102vw;
        margin-left: calc(-39vw + 35%);
        padding:9px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 110vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: #FFFFFF;
    }

    .toggleMenu{
        margin-bottom: 0 !important;
    }

    .toggleCloseMenu span{
        background-image: url("../../../assets/Close.svg") !important;
        max-width: 24px;
        margin-right: 28px;

    }


}

@media screen and (max-width: 400px) {
    .contactUs {
        font-size: 16px;
        font-family: var(--fmc-font--antenna);
        padding: 10px;
    }

    .joinSignIn {
        font-size: 13px;
        font-family: var(--fmc-font--antenna);
    }

    .myAccountContainer {
        font-size: 16px;
        font-family: var(--fmc-font--antenna);
        color: #000066;
        padding-left: 0;
        padding-top: 6px;
        float: left;
    }

    .headerFordLogo {
        padding: 9px 0;
        zoom: 80%;

    }

    .profileIcon{
        max-height: 20px;
    }
    .profileIcon > img {
        min-width: 25px;
        min-height: 20px;
        margin-top: 10px;
        margin-right: 15px;
        float: left;

    }

    .contactUs > img {
        margin-left: -18px;
        margin-right: 15px;
        margin-top: -5px;
    }



    .innerDropDown {
        background-color: #FFF;
        padding: 8px;
    }

    .accountInner {
        font-size: 16px;
        font-family: var(--fmc-font--antenna);

    }

    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
    }
    .toggleMenu{
        margin-bottom: 0 !important;
    }

}

@media screen and (max-width: 375px){
    .contactUs{
        padding: 15px 10px 15px 10px;
    }

    .headerFordLogo {
        padding: 9px 0;
        zoom: 75%;

    }

    .myAccountContainer > div{
        background-color: transparent;
    }
    .innerDropDown{
        width: 102vw;
        margin-left: calc(-39vw + 33%);
        padding:9px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 110vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }
    .myAccountContainer>a {
        color: #FFF !important;
        padding: 10px;
    }
    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;
        margin-right: 47px;

    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        color: #FFFFFF;
    }

    .toggleMenu{
        margin-bottom: 0 !important;
    }

}

@media screen and (max-width: 320px){
    .contactUs{
        padding-left: 11px;
    }

    .myAccountContainer > div{
        background-color: transparent;
    }
    .innerDropDown{
        width: 102vw;
        margin-left: calc(-44vw + 32%);
        padding:9px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 110vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }
    .myAccountContainer>a {
        color: #FFF !important;
        padding: 10px;
    }

    .topHeaderDivider {
        border-top: 1px solid #cdc9c982;

    }

    .headerFordLogo {
        padding: 9px 0;
        zoom: 70%;

    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }
    .headerFordLogo .logoTextSeparator{
        padding: 9px 0 0 0;
        margin-left: 12px;
        margin-top: 5px;
        height: 21px;
        width: 1px;
        opacity: 0.4;
        transform: scaleX(-1);
        background-color: #FFFFFF;
        font-family: var(--fmc-font--antenna) ;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .mobileMenuContainer{
        margin-right: 0 !important;
    }
    .toggleMenu{
        margin-bottom: 0 !important;
    }

    .toggleCloseMenu span{
        margin-top: 7px;
    }
}

@media screen and (max-width: 280px) {
    .contactUs{
        padding-left: 20px;
    }

    .headerFordLogo {
        padding: 9px 0;
        zoom: 60%;

    }

    .headerFordLogo .logoText{
        padding: 5px 0 0 10px;
        font-family: var(--fmc-font--antenna) ;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
    }
    .headerFordLogo .logoTextSeparator{
        padding: 9px 0 0 0;
        margin-left: 11px;
        width: 1px;
        opacity: 0.4;
        transform: scaleX(-1);
        background-color: #FFFFFF;
        font-family: var(--fmc-font--antenna) ;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .innerDropDown{

        margin-left: calc(-34vw + 10%);
        padding:9px;
        min-width:300px;
    }

    .innerDropDown  .dropdownItemDivider{
        width: 130vw;
        margin-left: calc(-50vw + 40%);
        border-top: 1px solid rgba(0, 0, 0, 0.42);

    }
}