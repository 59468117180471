.ICMainContainer {
    margin-bottom: 50px;
}

.whatIsIPContainer {
    text-align: center;
    padding: calc(35px + 1.5625vw);;
}

.whatIsIPContainer > h5 {
    margin-bottom: 16px;
    font-weight: normal;
}

.howDoesIPWorkContainer {
    text-align: center;
    background-color: #FFFFFF;
    padding: calc(35px + 1.5625vw);
}

.howDoesIPWorkContainerMob {
    text-align: left;
    background-color: #FFFFFF;
}


.CheckMarkIcon {
    width: 20px
}

.howDoesIPWorkContainer > h5 {
    font-weight: normal;
    margin-bottom: 16px;
}

.saveMoneyCarImg {
    width: 690px;
    height: auto;
}

.iconCircleMb {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
    border: 1px solid #000;
    color: #000;

}

.saveMoneyTxt {
    display: flex;
    gap: 4%;
    justify-content: space-between;
    align-items: center;
    font-family: var(--fmc-font--antenna);
    padding: calc(35px + 1.5625vw);
    color: #00095B;

}

.trackerContainer {
    justify-content: space-around !important;
    pointer-events: none;
}


.trackerStepCotnainer::before {
    left: 85% !important
}

.mainMiddleHeaderTxt {
    margin-bottom: 50px;
}

.saveMoneySubHeader {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}

.saveMoneySubHeaderDesc {
    margin-top: 8px;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.howDoesIPWorkContainerPRG2 {
    padding-top: 16px !important;
    margin-bottom: 60px;
}

.hrLineMobile {
    border-left: 1px solid #000;
    height: 102px;
    position: relative;
    left: 9%;
    margin-left: -3px;
    top: 0;
}


.iconDescContainerM {
    display: flex;
    margin: 20px 0 20px 0;
    align-items: center;
}

.iconDesc {
    margin-top: calc(2px + 0.9vw);
}

.iconDescM {
    margin-left: calc(6px + 0.9vw);
    text-align: left;
}

.takeChargeMain {
    background-color: #FFFFFF;
    padding: calc(35px + 1.5625vw);
}

.takeChargeContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 32px;
    row-gap: 32px;

}

.takeChargeBoxContainer {
    border: 1px solid #4d4d4d;
    padding: 50px;
}

.takeChargeHeaderTitle {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;
    font-family: var(--fmc-font--antenna);
    text-align: center;
    margin-bottom: 40px;
}

.takeChargeLogoIcon {
    margin-bottom: 28px;
}

.takeChargeTitle {
    margin-bottom: 14px;
    color: #00095B;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    font-family: var(--fmc-font--antenna);
}

.takeChargeDesc {
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    font-family: var(--fmc-font--antenna);
}

.homePowerTransferTitle {
    margin: 60px 0 32px 0;
    text-align: center;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 40px;

}


.powerTransferMain {
    display: grid;
    grid-template-columns: auto auto auto;
    color: #00095B;

}

.powerTransferMain div {
    padding: 10px;
}

.one {
visibility: hidden;
}

.two{
    text-align: center;
    padding: 25px 25px 0 25px !important;
}
.two, .five , .eight {
    background-color: #FFFFFF;
    margin-right: 24px;
}

.dividerIPEAP{
    border-bottom: 1px #717272 solid !important;
}

.benefitsMob{
    display: none;
}

.benefitsMobBlue{
    display: none;
}

.whatsNeededMob{
    color: #FFFFFF;
    display: none;
}

.whatsNeededMobBlue{
    display: none;
}
.three {
    text-align: center;
    padding: 25px 25px 0 25px !important;
}

.three, .six, .nine{
    background-color: #1700F4;
    color: #FFFFFF;

}

.four {

}

.five, .six {
padding-bottom: 40px !important;
}

.six li{
    color: #FFFFFF !important;
}

.eight, .nine {
    padding-bottom: 40px !important;

}
.eight li::marker, .five li::marker {
    content: "\25FE";
    font-size: 24px;
}


.nine li::marker, .six li::marker{
    content: "\25FC";

}

.nine li{
    color: #FFFFFF !important;
}

.nineLastPoint > span {
    font-size: 20px;
}

.joinWaitListBottom{
    text-align: center;
    padding: calc(35px + 1.5625vw);

}

.joinWaitListBottom > button{
    margin-bottom: 40px ;
}

.joinWaitListBottom a {
    text-decoration: underline;
}

.privacyIcon{
    width: 40px;
    height: 40px;
    margin-bottom: 30px;
}

.privacyFooter > div{
    background-color: #FFFFFF;
    text-align: center;
    padding: calc(35px + 1.5625vw);

}

.footerHeaderText{
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
}

.privacyPolicySubText > a{
    text-decoration: underline;
}

.privacyPolicySubText {
    margin-top: 16px ;
}

@media screen and (max-width: 1400px) {

    .trackerStepCotnainer::before {
        left: 80% !important
    }
}

@media screen and (max-width: 1100px) {

    .trackerStepCotnainer::before {
        left: 63% !important
    }
}

@media screen and (max-width: 1050px) {

    .trackerStepCotnainer::before {
        left: 60% !important
    }
}

@media screen and (max-width: 1030px) {

    .trackerStepCotnainer::before {
        left: 60% !important
    }
}

@media screen and (max-width: 996px) {

    .saveMoneyTxt {
        display: flex;
        flex-direction: column-reverse;
        gap: 3%;
        justify-content: space-between;
        align-items: center;
        font-family: var(--fmc-font--antenna);
        padding: calc(35px + 1.5625vw);
        color: #00095B;
    }

    .benefitsMob{
        display: revert;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        padding-top: 24px;
    }

    .benefitsMobBlue{
        display: revert;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        color: #FFFFFF;
        padding-top: 24px;
    }

    .whatsNeededMob{
        color: #FFFFFF;
        display: revert;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
    }

    .whatsNeededMobBlue{
        display: revert;
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
    }

    .two, .five , .eight {
        background-color: #FFFFFF;
        margin-right: 0;
    }


    .takeChargeBoxContainer {
        border: 1px solid #4d4d4d;
        padding: 40px;
    }

    .homePowerTransferTitle {
        margin: 60px 0 32px 0;
        text-align: left;
        font-size: 32px;
        letter-spacing: 0;
        line-height: 40px;
        padding-left: 20px;
    }

    .takeChargeHeaderTitle {
        font-size: 32px;
        letter-spacing: 0;
        line-height: 40px;
        font-family: var(--fmc-font--antenna);
        text-align: left;
        margin-bottom: 20px;

    }

    .takeChargeContainer {
        background-color: #FFFFFF;
        padding: 0;
        display: grid;
        grid-template-columns: auto;
        column-gap: 32px;
        row-gap: 32px;
    }

    .trackerStepCotnainer::before {
        left: 55% !important
    }

    .saveMoneyCarImg {
        width: 100%;
        height: auto;
    }

    .mainMiddleHeaderTxt {
        margin-top: 50px;
        text-align: left;
        margin-bottom: 30px;
    }

    .powerTransferMain {
        display: grid;
        grid-template-columns: auto;
    }

    .one, .four, .seven {

        display: none
    }
    .five{
        grid-row: 2;
    }

    .eight{
        grid-row: 3;
        margin-bottom: 24px;

    }


}

@media screen and (max-width: 630px) {
    .hrLineMobile {
        left: calc(20px + 0.9vw);
    }

    .CheckMarkIcon {
        width: 25px
    }

    .BatteryHealthIcon {
        width: 15px;
        margin: 0 5px;
    }


}

