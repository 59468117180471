.homeContainer {
    background-size: cover;
    background-image: linear-gradient(
            to bottom,
            #00142e 0%,
            rgba(0, 20, 46, 0) 50%
    ),
    url("../../../assets/Mach-E-IC.png");
    position: relative;
}


.mobileView {
    width: 100%;
    display: none;
}

.verticalSpan300 {
    padding-top: 280px !important;
    position: relative;
    top: -65px;
}

.eapTitleText {
    color: #ffffff !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 36px !important;
    letter-spacing: 0 !important;
    line-height: 43px !important;
}

.eapSubtitleText {
    color: #ffffff !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 24px !important;
    letter-spacing: 0 !important;
    line-height: 29px !important;
}

.verticalSpan100 {
    padding-top: 100px;
}

.verticalSpan300 {
    padding-top: 280px;
}

.billboardSubHeaderTextContainer{
    top:-50px !important;
    position: relative !important;
}

.billboardSubHeaderText  {
    text-decoration: underline;
    text-decoration-color: #FFFFFF;
    cursor: pointer;
}


.verticalSpanBottom100 {
    padding-bottom: 100px;
}

.expressInterestBtn{
    top:-60px;
    position: relative;
}

@media only screen and (max-width: 630px) {
    .homeContainer {
        background-image: linear-gradient(
                to bottom,
                #00142e 0%,
                rgba(0, 20, 46, 0) 50%
        ),
        url("../../../assets/Mach-E-IC-mobile.jpg") !important;
        top:0;
    }

    .expressInterestBtn{
        top:-50px;
        position: relative;
    }


    .mobileView {
        display: block !important;
    }

    .verticalSpan100 {
        padding-top: 100px !important;
    }

    .verticalSpan300 {
        padding-top: 80px !important;
        top:-20px;
    }



    .verticalSpanBottom100 {
        padding-bottom: 10px;
    }
}
