.backGroundImg{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size:96px;
    top:70%;
}

.stroke {
    font-size: 10em;
    -webkit-text-fill-color: #F4F4F4;
    -webkit-background-clip: text;
    -webkit-text-stroke: 2px #6E6E6E;
    line-height: 1em;
}

.container {
    position: relative;
    text-align: center;
    color: white;
    margin-bottom: 8em;

}

@media screen and (max-width: 630px) {
    .stroke {
        font-size: 70px;
        -webkit-text-fill-color: #F4F4F4;
        font-family: var(--fmc-font--antenna);
        background: -webkit-linear-gradient( 270deg,  #6e6e6e 17%,#F4F4F4 139%);
        -webkit-background-clip: text;
    }

    .container {
        position: relative;
        text-align: center;
        color: white;
        margin-bottom: 5em;
    }
}