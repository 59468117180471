.headerContainer{
    text-align: center;
    margin: 40px 100px 0 100px ;
}
.cardContainer{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    padding:10px;
}
.imageContainer{
    width: 280px;
    height: 280px;
    border-radius: 100%;
    border:#00095B 2px solid;
    overflow: hidden;
    text-align: center;
    align-items: center;
    line-height: 60px;
    color: white;
    margin-top: 40px;

}

.imageContainer img{
    transform: scale(0.35);
    transform-origin: 3% 0;
    margin: -20px  0  0 -100px;
}
.welcomeMessageContainer{
    margin-top: 40px;
    text-align: left;
}

.textContainer{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-top: 32px;
}

.textContainerCEO{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 32px;

}
.textContainerWelcome{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-top: 8px;

}
.textContainerRole{
    margin: 8px 0 58px 0;
    color: #4D4D4D;

}

.ceoMessage{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
}

@media screen and (max-width: 1091px){

    .cardContainer{
        flex-direction: column;
        align-items: center;
    }
    .imageContainer{
        transform: scale(0.8);
        transform-origin: 50% 0;
    }

    .welcomeMessageContainer{
        margin: 0 !important;
    }

    .headerContainer{
        text-align: center;
        margin: 10px 20px 0 20px ;
    }
}

@media screen and (max-width: 414px){

    .cardContainer{
        flex-direction: column;
        align-items: center;
    }

    .headerContainer{
        text-align: center;
        margin: 10px 20px 0 20px ;
    }
}
