.manageVehicle {
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 1.5em;
    text-align: center;
}

.labelContainer{
    display: revert !important;
}

.descriptionText {
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
}

.manageVehicleContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10em;
}

.box div {
    width: 100px;
    height: 100px;
}

.checkBoxText{
    text-align: left;
    padding-left: 35%;
}

.buttonContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2em;
}

.leaveProgramMessage{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    margin-top: 1em;
}