.pageTitle{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 28px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
}

.leftPanel{
    padding: 2em;
    display: flex;
    flex-direction: column;
}

.rightPanel{
    width: 100%;
    margin-top: 47px;
}

.vl {
    border-left: 2px solid #00095B;
    height: 86px;
    margin-left: 26px;
}

.vlPage {
    border-left: 1px solid black;
    padding: 0 1em 0 1em ;
}

.panelContainer{
    display: flex;
    align-content: start;
}

.mobileTopHr{
    height: 1px;
    opacity: 0.4;
    transform: scaleX(-1);
    background-color: #000000;
}