.were-sorry {
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center
}

.thank-you-for-your-i {
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
}

.cards-horizontal-building-bloc {
    text-align: center
}

.warning {
    margin-top: 10em;
    color: #D62D0A;
    text-align: center;
}