.description {
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
}
.feedBack{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-top: 16px;

}
.feedBack > a {
    text-decoration-color: #00095b;
    text-decoration: underline;
    color: #00095b;
    cursor: pointer;
}

.title {
    padding: 1.125em 0 1em 0;
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}

.icomDescription{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 14px;
    letter-spacing: 1px;
    padding-top: 17px;
    text-align: center;
    width: 190px;
}

.vl {
    border-right: 1px solid rgba(0, 0,0, 0.2);
    height: 72px;
    padding: 0 15px 0 15px;
    margin: 0;
    align-self: center;
}
.subHeaderText{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

ul{
    list-style-type: none;
    font-size: 18px;
    padding: 3em 0 3em 0;
}

li{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
}

.stepsStyle {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    line-height: 1px;
}

.stepsLastStyle {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
}

.stepsVl {
    border-left: 1px solid black;
    height: 72px;
    margin-left: 26px;
}
.modalContentText{
    padding: 0 160px 0 160px;
}


.disclaimer{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin: 1.5em 0 0 0;
}

.iconSubtitle{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
}

@media screen and (max-width: 630px) {
    .modalContentText{
        padding: 0;
    }

}