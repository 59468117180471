.myProductsTitle {
    font-family: var(--fmc-font--antenna);
    color: #00095B;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
    padding: 1em 0 1em 3.5em;
    background-color: #FFFFFF;
}

.seeWhatsComingOp {
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 0.5em 0 0.5em 0;
}

.tabLabel {
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-stretch: condensed;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
}

.alignMiddle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10em;
    text-align: center;
}

.fmc-tabs__tablist{
    --tablist-margin: 0 auto 1.6rem;
    justify-content: flex-start;
}

.tabTextContainer{
    margin: 0 !important;
}

.tabHeaderContainer{
    display: flex;
    justify-content: space-between;
}

.tabHeaderPadding{
    padding-left: 3em
}

.productCardTitle {
    color: #00095B;
    padding: 1em 1em 1em 0;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
}

.productCardDescription {
    margin-top: 20px;
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.productCardDescriptionContainer{
    display: flex;
    justify-content: space-between;
}

.invitedOn {
    padding: 1.2em 0 1em 0;
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.productTitleLabelContainer{
    background-color: #F4F4F4;
    height: 8em;
}

.productStatusLabel{
    color: #FFFFFF;
    font-family: var(--fmc-font--antenna);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    padding: 0.3em;
    width: fit-content;
}

.productTitleLabelFlex{
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.vehicleDropDown{
    width: 270px;
    margin: 0 3em 0 3em;
}

.productCardContainer{
    height: min-content !important;
}

@media screen and (max-width: 630px) {
    .tabHeaderContainer{
        display: flex;
        flex-direction: column;
    }
}