.homeContainer {
    background-size: cover;
    background-image: linear-gradient(to bottom, rgba(2,0,36, 0.40), rgba(2,0,36, 0.40)),
    url("../../../assets/Hero-image.png");
    height: 700px;
    position: relative;
}

.headerText {
    padding-top: 50px;
    color: #ffffff !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 60px;
    text-align: center;
}


.subHeaderText{
    color: #ffffff !important;
    font-family: var(--fmc-font--antenna) !important;
    margin-top: 32px;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
}


.waitListCTA{
    margin-top: 430px;
    text-align: center;
}

.billboardSubHeaderText  {
    text-decoration: underline;
    text-decoration-color: #FFFFFF;
    cursor: pointer;
}

.btmBillboardTxt{
    margin-top: 35px;
    color: #ffffff !important;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 16px;
    text-decoration: underline;
}

@media only screen and (max-width: 630px) {
    .homeContainer {
       background-image: linear-gradient(to bottom, rgba(2,0,36, 0.40), rgba(2,0,36, 0.40)),
        url("../../../assets/Hero-image.png") !important;
        height: 500px;
    }

    .headerText {
        padding: 50px 5px 0 5px;
        color: #ffffff !important;
        font-family: var(--fmc-font--antenna) !important;
        font-size: 50px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 60px;
        text-align: center;
    }



    .waitListCTA{
        margin-top: 160px;
        text-align: center;
    }

    .billboardSubHeaderText  {
        text-decoration: underline;
        text-decoration-color: #FFFFFF;
        cursor: pointer;
    }

    .btmBillboardTxt{
        margin-top: 35px;
        color: #ffffff !important;
        font-size: 11px;
        letter-spacing: 1px;
        line-height: 16px;
        text-decoration: underline;
    }
}


@media only screen and (max-width: 630px) {

    .waitListCTA{
        margin-top: 100px;
        text-align: center;
    }

}