.stepsHeader {
    color: #00095b !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 20px !important;
    letter-spacing: 0 !important;
    line-height: 24px !important;
    font-weight: normal !important;
}

.eapHeader {
    color: #00095b !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 32px !important;
    letter-spacing: 0 !important;
    line-height: 38px !important;
}
.description{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
}

.eapBody {
    color: #00095b !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
    line-height: 24px !important;
}


.eapFooter {
    color: #00095b !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 24px !important;
    letter-spacing: 0 !important;
    line-height: 29px !important;
}


.header{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding-bottom: 26px;
}

.imageView {
    max-width: 30px;
    width: 100%;
    padding:20px 0 21px 0 !important;

}

img[alt="participation"]
{
width: 24px !important;
}
.eapDef {
    max-width: 620px;
}
.middleContentTitle{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
    padding-bottom: 24px;
    margin-top: 20px;
}

.sample {
    color: #00095b !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
    line-height: 24px !important;
    text-align: center !important;
}

.mobileView {
    width: 100%;
    display: none;
}

.stepsOverAllHeader {
    color: #00095b !important;
    font-family: var(--fmc-font--antenna) !important;
    font-size: 32px !important;
    letter-spacing: 0 !important;
    line-height: 38px !important;
}

.whatIsEapImageContainer {
    position: relative;
    color: white;
}

.eapImageContainerBottomLeft {
    position: absolute;
    bottom: 30px;
    left: 50px;
}


@media only screen and (max-width: 599px) {

    .middleContent{
        padding: 0 20px 10px 20px;
    }

    .middleContentContainer{
        padding-top: 4rem;

    }
    .mobileView {
        display: block !important;
    }

    .desktopView {
        display: none !important;
    }

    .colStyles {
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }


    .eapDef {
        padding: 0 20px !important;
    }

    .eapHeader {
        font-weight: normal !important;
    }

    .carouselHeader {
        padding-top: 0 !important;
    }

    .noPadding {
        padding: 0 !important;
        margin: 0 !important;
    }

    .eapImageContainerBottomLeft {
        bottom: 6px !important;
        left: 20px !important;
    }
}
