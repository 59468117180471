.headerContainer{
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding:50px 0 32px 0;

}

input::before{
    --icon-size: 1rem !important;
}

.ENROLLED{
    font-family: var(--fmc-font--antenna);
    font-size: 12px;
    border-radius: 3px;
    padding: 0.4em;
    color: #FFFFFF;
    background-color: #00095B;
}

.WAITLISTED{
    font-family: var(--fmc-font--antenna);
    font-size: 12px;
    padding: 0.4em;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #BA4E00;
}

.INVITED{
    font-family: var(--fmc-font--antenna);
    font-size: 12px;
    padding: 0.4em;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #008200;
}

.NOT_INVITED{
    font-family: var(--fmc-font--antenna);
    font-size: 12px;
    padding: 0.4em;
    border-radius: 3px;
    color: #FFFFFF;
    background-color: #BA4E00;
}

.privacyHeader{
    text-align: center;
}

.VinCheckBoxStyle{
    margin-left: 10px;
}

.VinCheckBoxStyleContainer{
    margin-left: 20px;
}
.FeatureConsentContainer{
    margin-left: 35px;
}
.privacyLinks{
    text-decoration: underline;
    text-decoration-color: #00095B;
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    cursor: pointer;
}

.headerText{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
}
.headerTextAccordion{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    font-weight: 500;
    text-align: left;

}

.subHeaderText{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 25px;
    text-align: center;
}

.carContainer{
    margin-top: 48px;
}

.yourChance{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 38px;
}

.chanceDescription{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    margin-top: 16px;
}

.circleIcon{
    margin: auto;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: #4d4d4d 1px solid;
    overflow: hidden;
    text-align: center;
    align-items: center;
    line-height: 60px;
    color: white;
}

.separatorContainer{
    margin-top: 38px;
    padding-top: 22px;
    margin-bottom: 40px;
}

.separatorContainer > div{
    color: #00095B;
    font-family: var(--fmc-font--antenna);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 16px;
}

.topContentContainer{
    margin-bottom: 60px;
}

.privacy > div{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    line-height: 24px;
    margin-left: 25px;
}
.PrivacyContainer{
    margin-left: 36px;
}

.privacy a{
    color: #00095B;

}
.termsDesc{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    margin-bottom: 4rem;
}
.middleContainer{
    margin: 0 100px;
}
.contactDesc{
    color: #4D4D4D;
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.divider {
    opacity: 0.4;
    transform: scaleX(-1);
    background-color: #000000;
    margin: 0
}

@media screen and (max-width: 991px){
    .middleContainer{
        margin: 0 20px;
    }

    .privacy > div{
        padding: 0  0  0 10px !important;
        margin: 0 !important;
    }
    .contactDesc{
        margin-bottom: 10px;
    }

    .circleIcon{
        text-align: center !important;
        line-height: 56px;

    }
}

@media screen and (max-width: 414px){

    .middleContainer{
        margin: 0 20px;
    }

    .privacy > div{
        padding: 0  0  0 10px !important;
    }

    .contactDesc{
        margin-bottom: 10px;
    }

    .circleIcon{
        text-align: center !important;
        line-height: 56px;

    }

}