.modalBackdrop {
    position: fixed; /* Stay in place */
    z-index: 1001; /* Sit on top */
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal {
    position: absolute;
    left: 50% !important;
    top: 50%;
    z-index: 1050;
    display: flex;
    transform: translate(-50%, -50%);
}

.modalContent {
    position: relative;
    max-width: 818px;
    background-color: #fefefe;
    padding: 10px;
    margin: auto;
    border: 1px solid #888;
    width: 100%;
    max-height: 509px;
    overflow-y: scroll;
}

.modelHeader {
    color: #00095b;
    font-family: "Ford Antenna";
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;

}

.modelContentText {
    color: #00095b;
    font-family: "Ford Antenna";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
}

.close{
    /*position: fixed;*/
    text-align: right !important;
    float: right !important;
}

.close:focus {
    font-weight: bold !important;
}

.close :hover {
    font-weight: bold !important;
}

.modelContentInner {
    padding: 40px;
}

@media screen and (max-width: 630px) {
    .modalBackdrop {
        padding-top: 110px !important; /* Location of the box */
    }

    .modalContent {
        max-width: 335px !important;
    }

    .modelContentInner {
        padding: 40px 20px !important;
    }
}
