.questionnaireTitle {
    color: #00095B;
    font-family: "Ford Antenna";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}

.questionnaireDescription{
    color: #4D4D4D;
    font-family: "Ford Antenna";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 1em 0 2em 0;
}

.optionsStyle {
    color: #00095B;
    font-family: "Ford Antenna";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
}

.questionStyle {
    color: #4D4D4D;
    font-family: "Ford Antenna";
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 24px;
    padding: 1em 0 1em 0;
}

.charFeedBack{
    color: #4D4D4D;
    font-family: "Ford Antenna";
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
}

.phoneNote{
    color: #4D4D4D;
    font-family: "Ford Antenna";
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 24px;
    padding-top: 5px;
}

.questionDiv{
    margin: 0 0 0 0;
}

textarea{
    background-color: inherit;
}