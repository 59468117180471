.contactUsContainer{
    margin:60px 0 60px;
}

.contactUsContainer > h4{
    margin-bottom: 68px;
}

.messageContainer{
    margin-left: 200px !important;
    margin-right: 200px !important;

}
.messageContainer > div{
    padding: 5px 30px 30px 5px !important;
}
.messageContainer h6{
    font-family: var(--fmc-font--antenna);
    font-stretch: normal;
    font-weight: 500;
    font-size: 24px;

}
.messageContainer p {
    font-family: var(--fmc-font--antenna);
    font-size: 16px;
    font-stretch: normal;
    font-weight: 500;
}


@media screen and (max-width: 768px) {
    .messageContainer > div{
        padding:0 !important;
    }

    .messageContainer{
        margin: 0 5px 5px 5px !important;
    }

}